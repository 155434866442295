import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import toast from 'react-hot-toast';
import api from '../utils/api';
import LoadingOverlay from '../components/loader/LoadingOverlay';

const AddChannels = () => {
  // State for all forms
  const [formData, setFormData] = useState([
    {
      id: 1,
      hallName: 'Auditorium',
      channelName: '',
      channelToken: '',
      appId: '',
      isLoading: false,
    },
    {
      id: 2,
      hallName: 'President Office',
      channelName: '',
      channelToken: '',
      appId: '',
      isLoading: false,
    },
    {
      id: 3,
      hallName: 'Board Room',
      channelName: '',
      channelToken: '',
      appId: '',
      isLoading: false,
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setFormData((prevFormData) =>
      prevFormData.map((form) =>
        form.id === id ? { ...form, [name]: value } : form
      )
    );
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    setIsLoading(true);

     // Set loading state for the specific form
  setFormData((prevFormData) =>
    prevFormData.map((form) =>
      form.id === id ? { ...form, isLoading: true } : form
    )
  );

    const selectedForm = formData.find((form) => form.id === id);

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = user?.token;

      const response = await api.post(
        `/api/editSettings/${selectedForm.id}`,
        selectedForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.success) {
        toast.success("Success");
         // Reset fields (except hallName) for the submitted form
         setFormData((prevFormData) =>
          prevFormData.map((form) =>
              form.id === id
                  ? {
                        ...form,
                        channelName: '',
                        channelToken: '',
                        appId: '',
                        isLoading:false,
                    }
                  : form
          )
      );
      } else {
        toast.error(response.message || 'Failed to save settings.');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      toast.error(errorMessage);
      // Reset loading state on error
    setFormData((prevFormData) =>
      prevFormData.map((form) =>
        form.id === id ? { ...form, isLoading: false } : form
      )
    );
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = (form) => (
    <form
      className="space-y-6 md:w-[50%] mx-auto"
      key={form.id}
      onSubmit={(e) => handleSubmit(e, form.id)}
    >
      <h1 className="text-3xl font-bold mb-8 text-center text-white">{form.hallName}</h1>

      <div>
        <label htmlFor={`hallName-${form.id}`} className="block text-white mb-2">
          Hall Name
        </label>
        {/* Read-only hallName field */}
        <input
          type="text"
          name="hallName"
          id={`hallName-${form.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          value={form.hallName}
          readOnly
        />
      </div>

      <div>
        <label htmlFor={`channelName-${form.id}`} className="block text-white mb-2">
          Channel Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="channelName"
          id={`channelName-${form.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          placeholder="Enter Channel Name"
          value={form.channelName}
          onChange={(e) => handleChange(e, form.id)}
          required
        />
      </div>

      <div>
        <label htmlFor={`channelToken-${form.id}`} className="block text-white mb-2">
          Channel Token <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="channelToken"
          id={`channelToken-${form.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          placeholder="Enter Channel Token"
          value={form.channelToken}
          onChange={(e) => handleChange(e, form.id)}
          required
        />
      </div>

      <div>
        <label htmlFor={`appId-${form.id}`} className="block text-white mb-2">
          App Id <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="appId"
          id={`appId-${form.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          placeholder="Enter App Id"
          value={form.appId}
          onChange={(e) => handleChange(e, form.id)}
          required
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="bg-yellow-500 text-white py-3 px-16 rounded-full shadow-sm hover:bg-yellow-600"
          disabled={form.isLoading}
        >
          {form.isLoading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <span className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5"></span>
                    <span>Loading...</span>
                  </div>
                ) : (
                  'Submit'
                )}
          {/* {form.isLoading ? 'Submitting...' : 'Submit'} */}
        </button>
      </div>
      <LoadingOverlay isActive={form.isLoading} />
    </form>
  );

  return (
    <DashboardLayout>
      <div className="p-4 space-y-12">
        {formData.map(renderForm)}
      </div>
    </DashboardLayout>
  );
};

export default AddChannels;
