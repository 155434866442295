import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { FaWpforms } from 'react-icons/fa6';
import { CiViewList } from 'react-icons/ci';
import { LanguageContext } from '../context/LanguageContext';

export default function DashboardLayout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [role, setRole] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { language, toggleLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setRole(user.role);
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white';
  };

  const showLanguageToggle = location.pathname === '/dashboard/form';

  return (
    <div className="flex h-screen ">
      <div className="flex-1 flex flex-col ">
        <header className="bg-[#031122] text-white p-4 flex items-center justify-between">
          <img src="/logo.svg" alt="Logo" className="h-8" />
          <div className="flex items-center">
            {showLanguageToggle && (
              <button
                onClick={toggleLanguage}
                className={`flex items-center px-4 py-2 rounded-full transition-colors duration-300 
                  ${language === 'en' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
              >
                <span className="mr-2">{language === 'en' ? 'عربي' : 'English'}</span>
                <span className="text-xl">{language === 'en' ? '🌍' : '🇬🇧'}</span>
              </button>
            )}
            {!isSidebarOpen && (
              <button onClick={toggleSidebar} className="text-2xl ml-4">
                <FaBars />
              </button>
            )}
          </div>
        </header>
        <main className="flex-1  bg-[#0a223f]">
          {children}
        </main>
      </div>
      <div className={`bg-[#031122] text-white transition-all duration-300 ${isSidebarOpen ? 'w-64' : 'w-0'}  flex flex-col`}>
        {isSidebarOpen && (
          <>
            <div className="flex items-center justify-end p-4">
              <button onClick={toggleSidebar} className="text-2xl">
                <FaBars />
              </button>
            </div>
            <nav className="flex flex-col p-4 space-y-2 flex-1">
              {role === 'admin' && (
                <> 
                <Link to="/dashboard/listing-applicants" className={`flex items-center space-x-2 p-2 rounded ${getLinkClass('/dashboard/listing-applicants')}`}>
                  <CiViewList />
                  <span>Applicants</span>
                </Link>
                 <Link to="/dashboard/add-channels" className={`flex items-center space-x-2 p-2 rounded ${getLinkClass('/dashboard/listing-applicants')}`}>
                 <CiViewList />
                 <span>Add Channel Ids</span>
               </Link>
               <Link to="/dashboard/view-channels" className={`flex items-center space-x-2 p-2 rounded ${getLinkClass('/dashboard/listing-applicants')}`}>
                 <CiViewList />
                 <span>View Channels</span>
               </Link>
                </>
              )}
              {role === 'user' && (
                <Link to="/dashboard/form" className={`flex items-center space-x-2 p-2 rounded ${getLinkClass('/dashboard/form')}`}>
                  <FaWpforms />
                  <span>Form</span>
                </Link>
              )}
            </nav>
            <button
              onClick={handleLogout}
              className="flex items-center space-x-2 p-4 bg-red-600 hover:bg-red-700 text-white rounded m-4 mt-auto"
            >
              <span>Logout</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}