import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ApplicationStatusPage from '../../pages/ApplicationStatusPage';

const ProtectedRoute = ({ children, role, requireFormSubmission = false, requireNoFormSubmission = false }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { status } = useParams();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (role && user.role !== role) {
    return <Navigate to="/login" />;
  }

  if (user.is_app_submit === 0 && (status === 'accepted' || status === 'rejected' || requireFormSubmission)) {
    return <Navigate to="/dashboard/form" />;
  }

  if (requireFormSubmission && user.is_app_submit === 0) {
    return <Navigate to="/dashboard/form" />;
  }

  if (requireNoFormSubmission && user.is_app_submit === 1) {
    return <Navigate to="/thank-you" />;
  }

  if (status && (status === 'accepted' || status === 'rejected')) {
    return <ApplicationStatusPage status={status.charAt(0).toUpperCase() + status.slice(1)} />;
  }

  return children;
};

export default ProtectedRoute;