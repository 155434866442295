import React, { useEffect, useState } from 'react'
import DashboardLayout from '../components/DashboardLayout'
import api from '../utils/api';
import toast from 'react-hot-toast';
import LoadingOverlay from '../components/loader/LoadingOverlay';

const ViewChannels = () => {
  const [hallData, setHallData] = useState(null);
  const [isApiLoading, setIsApiLoading] = useState(false)

  const fetchApplicants = async () => {
    setIsApiLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;

    try {
      const response =await api.get("/api/listSettings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        setHallData(response.data);
      } else {
        console.error('Error fetching applicants:', response.message);
        toast.error('Failed to load applicants.');
      }
    } catch (error) {
      console.error('Error fetching applicants:', error);
      // toast.error('An error occurred while fetching applicants.');
    } finally {
      setIsApiLoading(false);
    }
  };

  useEffect(()=>{
    fetchApplicants();
  },[])

  return (
   <DashboardLayout>
    {
      isApiLoading ? <LoadingOverlay isActive={isApiLoading} />:
      hallData?.map((hall,index)=>(

 <form
      className="space-y-6 my-5 md:w-[50%] mx-auto"
      // key={form.id}
      // onSubmit={(e) => handleSubmit(e, form.id)}
    >
      <h1 className="text-3xl font-bold my-4 text-center text-white">{hall?.hallName}</h1>

      <div>
        <label htmlFor={`hallName-${hall?.id}`} className="block text-white mb-2">
          Hall Name
        </label>
    
        <input
          type="text"
          name="hallName"
          id={`hallName-${hall?.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          value={hall?.hallName}
          readOnly
        />
      </div>

      <div>
        <label htmlFor={`channelName-${hall?.id}`} className="block text-white mb-2">
          Channel Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="channelName"
          id={`channelName-${hall?.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          placeholder="Enter Channel Name"
          value={hall?.channelName}
          readOnly

        />
      </div>

      <div>
        <label htmlFor={`channelToken-${hall?.id}`} className="block text-white mb-2">
          Channel Token <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="channelToken"
          id={`channelToken-${hall?.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          placeholder="Enter Channel Token"
          value={hall?.channelToken}
          readOnly

        />
      </div>

      <div>
        <label htmlFor={`appId-${hall?.id}`} className="block text-white mb-2">
          App Id <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="appId"
          id={`appId-${hall?.id}`}
          className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
          placeholder="Enter App Id"
          value={hall?.appId}
          readOnly

        />
      </div>

      {/* <div className="flex justify-end">
        <button
          type="submit"
          className="bg-yellow-500 text-white py-3 px-16 rounded-full shadow-sm hover:bg-yellow-600"
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </div> */}
    </form>
      ))
    }
   </DashboardLayout>
  )
}

export default ViewChannels