import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import LandingPage from './pages/LandingPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import DashboardForm from './pages/DashboardForm';
import DashboardListingApplicants from './pages/DashboardListingApplicants';
import Download from './pages/Download';
import ThankYouPage from './pages/ThankYouPage'
import ApplicationStatusPage from './pages/ApplicationStatusPage';
import { Toaster } from 'react-hot-toast';
import { LanguageProvider } from './context/LanguageContext';
import AddChannels from './pages/AddChannels';
import ViewChannels from './pages/ViewChannels';

export default function App() {
  
  // Play music when user interact
  useEffect(() => {
    const audio = new Audio('/song.mp3');
    audio.loop = true;

    const enableAudio = async () => {
      try {
        await audio.play();
        console.log('Audio is playing');
      } catch (error) {
        console.error('Audio play failed:', error);
      }
    };

    const handleUserInteraction = () => {
      enableAudio();
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };

    // Add event listeners for user interaction
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('keydown', handleUserInteraction);

    return () => {
      audio.pause();
      audio.currentTime = 0;
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, []);
  return (
    <LanguageProvider>
      <Router>
        <Toaster/>
        <div className="min-h-screen bg-[#EAECED] flex flex-col">
          <Routes>
            <Route path="/" element={<Navigate to="/landing" />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/download" element={<Download />} />
            <Route 
              path="/thank-you" 
              element={<ProtectedRoute role="user" requireFormSubmission={true}><ThankYouPage /></ProtectedRoute>} 
            />
            <Route 
              path="/dashboard/form" 
              element={<ProtectedRoute role="user" requireNoFormSubmission={true}><DashboardForm /></ProtectedRoute>} 
            />
            <Route path="/dashboard/listing-applicants" element={<ProtectedRoute role="admin"><DashboardListingApplicants /></ProtectedRoute>} />
            <Route path="/dashboard/add-channels" element={<ProtectedRoute role="admin"><AddChannels /></ProtectedRoute>} />
            <Route path="/dashboard/view-channels" element={<ProtectedRoute role="admin"><ViewChannels /></ProtectedRoute>} />
            <Route path="/status/:status" element={<ProtectedRoute role="user"><ApplicationStatusPage /></ProtectedRoute>} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}