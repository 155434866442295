import React from 'react';
import Modal from 'react-modal';

const API_BASE_URL = "https://api.dmms.io/storage";

const ApplicantDetailsModal = ({ isOpen, applicant, onRequestClose }) => {
  const cleanFilePath = (path) => path.replace(/\\/g, '/');

  const decodeUnicodeString = (str) => {
    return decodeURIComponent(JSON.parse('"' + str.replace(/\"/g, '\\"') + '"'));
  };

  const parseFiles = (files) => {
    try {
      return JSON.parse(files);
    } catch (e) {
      return [];
    }
  };

  const renderFileLinks = (files) => {
    return files.map((file, index) => (
      <a
        key={index}
        href={`${API_BASE_URL}/${cleanFilePath(file)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 block"
      >
        {`File ${index + 1}`}
      </a>
    ));
  };

  const renderSocialLink = (platform, url) => {
    return url ? (
      <a
        href={url.startsWith('http') ? url : `https://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500"
        aria-label={platform}
      >
        {platform}
      </a>
    ) : 'N/A';
  };

  const attachedFiles = applicant.attach_file ? parseFiles(applicant.attach_file) : [];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Applicant Details"
      className="bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto my-20 max-h-[70vh] overflow-y-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <h2 className="text-2xl font-bold mb-4">Applicant Details</h2>
      <table className="w-full text-left border-collapse">
        <tbody>
          {[
            { label: 'Full Name', value: applicant.full_name_arabic || 'N/A' },
            // { label: 'Name (English)', value: applicant.full_name_english || 'N/A' },
            { label: 'Age', value: applicant.age_in_year ? decodeUnicodeString(applicant.age_in_year) : 'N/A' },
            { label: 'Email', value: applicant.email || 'N/A' },
            { label: 'Gender', value: applicant.gender || 'N/A' },
            { label: 'Status', value: applicant.status || 'N/A' },
            { label: 'Nationality', value: applicant.nationality ? decodeUnicodeString(applicant.nationality) : 'N/A' },
            { label: 'Permanent Residence', value: applicant.permanent_residence ? decodeUnicodeString(applicant.permanent_residence) : 'N/A' },
            { label: 'Current Occupation', value: applicant.current_occupation ? decodeUnicodeString(applicant.current_occupation) : 'N/A' },
            { label: 'Institute Name', value: applicant.institute_name || 'N/A' },
            { label: 'Certificate', value: applicant.certificate ? decodeUnicodeString(applicant.certificate) : 'N/A' },
            { label: 'Specialization', value: applicant.specialization || 'N/A' },
            { label: 'Experience', value: applicant.experience || 'N/A' },
            { label: 'Condition Question', value: applicant.condition_question ? decodeUnicodeString(applicant.condition_question) : 'N/A' },
            { label: 'Is Union Member', value: applicant.is_union_member ? 'Yes' : 'No' },
            { label: 'Union Belong', value: applicant.union_belong || 'N/A' },
            { label: 'Phone No', value: applicant.phone_no || 'N/A' },
            { label: 'LinkedIn', value: renderSocialLink('LinkedIn', applicant.linkedin) },
            { label: 'Instagram', value: renderSocialLink('Instagram', applicant.instagram) },
            { label: 'Facebook', value: renderSocialLink('Facebook', applicant.facebook) },
            { label: 'YouTube Channel', value: renderSocialLink('YouTube Channel', applicant.youtube_channel) },
            { label: 'Reference Name', value: applicant.refrence_name || 'N/A' },
            { label: 'Reference Phone No', value: applicant.refrence_phone_no || 'N/A' },
            { label: 'Attached Files', value: attachedFiles.length > 0 ? renderFileLinks(attachedFiles) : 'N/A' },
            { label: 'Image', value: applicant.image ? (
              <a href={`${API_BASE_URL}/${cleanFilePath(applicant.image)}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                View Image
              </a>
            ) : 'N/A' },
          ].map(({ label, value }, index) => (
            <tr key={index} className="border-b">
              <td className="font-bold p-2">{label}:</td>
              <td className="p-2">{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={onRequestClose} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600">
        Close
      </button>
    </Modal>
  );
};

export default ApplicantDetailsModal;