import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import api from '../utils/api';
import LoadingOverlay from '../components/loader/LoadingOverlay';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const navigate = useNavigate();

  const isFormValid = name && email && password && confirmPassword && passwordMatch;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordMatch) return;

    setIsLoading(true);
    try {
      const response = await api.post('/api/signup', { name, email, password });
      if (response.success) {
        toast.success(response.message);
        navigate('/login');
      } else {
        toast.error(response.data);
      }
    } catch (error) {
      const errorMessage = error.response?.message || error.message || 'Error';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordMatch(value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setPasswordMatch(value === password);
  };

  return (
    <div className="min-h-screen flex">
      <div className="hidden md:flex md:w-2/5 bg-no-repeat bg-cover relative" style={{ backgroundImage: 'url(/bg_form_img.png)' }}>
        <div className="bg-[#031122] text-white p-4 flex items-center fixed w-full z-50">
          <img src="/logo.svg" alt="Logo" className="h-8" />
        </div>
        <div className="absolute top-0 left-0 text-white p-4 pt-16 overflow-y-auto h-[calc(100vh-1rem)] w-full z-40 custom-scrollbar pb-16">
          <h1 className="text-2xl lg:text-4xl font-bold">
            Welcome to the Digital Media & Marketing Syndicate of Lebanon
          </h1>
          <p className="mt-4 text-sm leading-tight">
            Dear Future Members,
            <br /><br />
            On behalf of the Digital Media & Marketing Syndicate, I am delighted to welcome you to our evolving digital platform. As we embark on this exciting journey, we invite you to join our vibrant community of innovators, thinkers, and creators who are shaping the future of digital media and marketing in Lebanon and beyond.
            <br /><br />
            We are thrilled to announce that those who become members of our syndicate will gain exclusive access to our first-in-class virtual reality premises. This unique space will enable you to learn and engage in the metaverse, connecting with top talented trainers, mentors, coaches, motivational speakers, and some of the best global experts in the field. This immersive learning experience will allow you to transcend traditional boundaries and participate in a dynamic environment that fosters creativity and innovation.
            <br /><br />
            While our website is currently under construction, we encourage you to fill out our membership application. Your application will allow our management team to assess your suitability within our dynamic syndicate, and we will provide you with timely feedback. By becoming a member, you will gain exclusive access to invaluable insights into the ever-evolving landscape of digital media and marketing, along with unique services designed to enhance your learning and professional growth.
            <br /><br />
            <strong>What We Offer:</strong>
            <ul className="list-disc list-inside ml-4">
              <li>Continuous Education Programs</li>
              <li>Entrepreneurship Boot Camp</li>
              <li>Virtual Reality Learning and Networking Experience</li>
              <li>Innovation Lab</li>
              <li>Access to Skilled Networks</li>
              <li>Strategic Investing Partners and Legal Affairs Consultancy</li>
            </ul>
            <br />
            We believe that the future of digital media and marketing is collaborative, and we are excited to support your growth within our syndicate. Together, we can push the boundaries of what is possible and create a vibrant community that thrives on innovation.
            <br /><br />
            Thank you for considering membership with the Digital Media & Marketing Syndicate. We look forward to welcoming you aboard and embarking on this journey together.
            <br /><br />
            <strong>Warm regards,</strong>
            <br />
            <strong>Patrick Abou Chacra</strong>
            <br />
            <strong>President, Digital Media & Marketing Syndicate of Lebanon</strong>
          </p>
        </div>
      </div>
      
      <div className="w-full md:w-3/5 bg-[#0a223f] flex items-center justify-center p-4">
        <div className="max-w-md w-full p-6 mt-8">
          <h1 className="text-2xl font-bold mb-4 text-center text-white">Create an Account</h1>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-white mb-1">
                Your Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 block w-full rounded-lg border border-white shadow-sm p-3 bg-transparent text-white focus:outline-none"
                placeholder="Your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-white mb-1">
                Your Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="mt-1 block w-full rounded-lg border border-white shadow-sm p-3 bg-transparent text-white focus:outline-none"
                placeholder="name@company.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-white mb-1">
                Password <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className="mt-1 block w-full rounded-lg border border-white shadow-sm p-3 bg-transparent text-white focus:outline-none"
                placeholder="Enter Your Password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-white mb-1">
                Re-enter Password <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="mt-1 block w-full rounded-lg border border-white shadow-sm p-3 bg-transparent text-white focus:outline-none"
                placeholder="Re-enter Your Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              {confirmPassword && !passwordMatch && (
                <p className="text-red-500 text-sm mt-2">Passwords do not match</p>
              )}
              {confirmPassword && passwordMatch && (
                <p className="text-green-500 text-sm mt-2">Passwords match</p>
              )}
            </div>
            <p className="text-sm font-light text-white text-center">
              Already have an account? <Link to="/login" className="font-medium text-primary-600 hover:underline">Login here</Link>
            </p>
            <div className="flex justify-center">
              <button
                type="submit"
                className={`bg-yellow-500 text-white py-3 px-16 rounded-full shadow-sm hover:bg-yellow-600 ${!isFormValid || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!isFormValid || isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <span className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5"></span>
                    <span>Loading...</span>
                  </div>
                ) : (
                  'Create an Account'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      
      <Toaster />
      <LoadingOverlay isActive={isLoading} />
    </div>
  );
}