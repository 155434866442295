import React from 'react';
import Modal from 'react-modal';
import LoadingOverlay from '../components/loader/LoadingOverlay';

const ChangeStatusModal = ({
  isOpen,
  selectedStatus,
  onRequestClose,
  onStatusChange,
  onConfirm,
  isLoading,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Change Status Confirmation"
        className="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto my-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        shouldCloseOnOverlayClick={false}
      >
        <h2 className="text-xl font-bold mb-4">Change Status</h2>
        <p>Select the new status for this application:</p>
        <div className="mt-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="status"
              value="Accepted"
              checked={selectedStatus === 'Accepted'}
              onChange={onStatusChange}
              className="form-radio text-blue-500"
            />
            <span className="ml-2">Accepted</span>
          </label>
          <label className="inline-flex items-center ml-4">
            <input
              type="radio"
              name="status"
              value="Rejected"
              checked={selectedStatus === 'Rejected'}
              onChange={onStatusChange}
              className="form-radio text-blue-500"
            />
            <span className="ml-2">Rejected</span>
          </label>
        </div>
        <div className="mt-6 flex justify-end">
          <button
            onClick={onRequestClose}
            className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600 mr-2"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center justify-center space-x-2">
                <span className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5"></span>
                <span>Loading...</span>
              </div>
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </Modal>
      <LoadingOverlay isActive={isLoading} />
    </>
  );
};

export default ChangeStatusModal;