import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { API_BASE_URL } from '../utils/api';

const Download = () => {
  const handleDownloadPC = () => {
    const downloadUrl = `${API_BASE_URL}/storage/uploads/application.zip`;
    window.open(downloadUrl, "_blank");
  };


  const handleDownloadMac = () => {
    const downloadUrl = `${API_BASE_URL}/storage/uploads/LivonMetaverse_MacBuild.zip`;
    window.open(downloadUrl, "_blank");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4">
      <FaCheckCircle className="text-green-500 text-6xl mb-4" />
      <h1 className="text-2xl font-bold mb-2">Congratulations!</h1>
      <p className="text-lg mb-6">Please click the button below to download the game. Enjoy your adventure!</p>
     <div className="flex gap-3">
      <button
        onClick={handleDownloadPC}
        className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition-colors duration-300"
      >
        Download PC Version
      </button>
    
      <button
        onClick={handleDownloadMac}
        className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition-colors duration-300"
      >
        Download MacBook Version
      </button>
     </div>
    </div>
  );
};

export default Download;
