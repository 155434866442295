import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
  const navigate = useNavigate();

  const handleSubscribeClick = () => {
    navigate('/login');
  };

  return (
    <div 
      className="min-h-screen bg-cover bg-center flex flex-col justify-between"
      style={{ backgroundImage: 'url(/landing_bg_2.png)' }}
    >
      <div className="w-full p-4 flex justify-start">
        <img 
          src="/logo.svg" 
          alt="Logo" 
          className="max-w-[50%] md:max-w-[35%] lg:max-w-[30%] xl:max-w-[25%] 2xl:max-w-[20%] 3xl:max-w-[18%] h-auto" 
        />
      </div>

      <div className="flex flex-col justify-center items-center text-center text-white flex-grow">
        <div className="mb-6">
          <div className="block leading-tight">
            <span className="text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl 3xl:text-5xl" style={{ color: '#A7A2A2' }}>
              THE WEBSITE IS
            </span>
            <span className="font-bold text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl 3xl:text-5xl text-white">
              {' '}UNDER
            </span>
          </div>
          <div>
            <span 
              className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-8xl 3xl:text-9xl font-bold"
              style={{ color: '#D12227', WebkitTextStroke: '1px #D12227', WebkitTextFillColor: 'transparent' }}
            >
              CONSTRUCTION
            </span>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center text-center text-white w-full md:w-auto">
          <div className="text-center md:text-left md:w-[80%] lg:w-[35%] 2xl:w-[30%] 3xl:w-[28%]">
            <p className="text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl 3xl:text-5xl font-bold">
              Exciting things are brewing!
            </p>
            <p className="mt-2 text-xs md:text-base lg:text-lg xl:text-xl 2xl:text-3xl 3xl:text-4xl">
              Our website is currently under construction, but stay tuned for an extraordinary digital experience coming your way soon.
            </p>
            <p className="mt-2 text-xs md:text-base lg:text-lg xl:text-xl 2xl:text-3xl 3xl:text-4xl text-red-600 font-bold">
              Get Ready to witness the magic!
            </p>

            <div className="mt-4">
              <button 
                className="bg-yellow-500 text-white py-2 px-4 rounded-full shadow-md hover:bg-yellow-600 transition-colors duration-300 text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-2xl 3xl:text-3xl"
                onClick={handleSubscribeClick}
                aria-label="Subscribe to updates"
              >
                Subscribe Here
              </button>
            </div>
          </div>
          <div className="mt-6 md:mt-0 md:w-[45%] flex flex-col items-center">
            <img 
              src="/landing_frame.png" 
              alt="Landing Frame" 
              className="w-[75%] md:w-[70%] lg:w-[65%] xl:w-[60%] 2xl:w-[60%] 3xl:w-[70%] h-auto"
            />
            <div className="text-white flex items-center justify-center space-x-2 mt-4">
              <a href="https://www.facebook.com/menalivon" target="_blank" rel="noopener noreferrer">
                <img src="/icons/facebook.png" alt="Facebook" className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8" />
              </a>
              <a href="https://twitter.com/livonmena" target="_blank" rel="noopener noreferrer">
                <img src="/icons/twitter.png" alt="Twitter" className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8" />
              </a>
              <a href="https://www.instagram.com/menalivon/" target="_blank" rel="noopener noreferrer">
                <img src="/icons/instagram.png" alt="Instagram" className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8" />
              </a>
            </div>
            <div className="text-white text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl mt-1">
              www.dmms.io
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 right-0">
        <img 
          src="/landing_corner.png" 
          alt="Corner Decoration" 
          className="w-24 h-32 md:w-40 md:h-50 lg:w-[12rem] lg:h-[16rem] xl:w-[18rem] xl:h-[22rem] 2xl:w-[24rem] 2xl:h-[28rem]" 
        />
      </div>
    </div>
  );
}
